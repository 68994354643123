import { createRoot } from "react-dom/client";

import * as serviceWorker from "./serviceWorker";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";

import { MatxLayout, MatxSuspense, MatxTheme } from "app/components";
// ALL CONTEXTS

import Analytics from "./app/views/dashboard/Analytics";

import JwtRegister from "app/views/sessions/JwtRegister";

import SettingsProvider from "app/contexts/SettingsContext";
import JwtLogin from "app/views/sessions/JwtLogin";
// ROUTES



// third party style
import "perfect-scrollbar/css/perfect-scrollbar.css";
import JwtProfile1 from "app/views/sessions/JwtProfile1";
import JwtProfile2 from "app/views/sessions/JwtProfile2";
import JwtProfile3 from "app/views/sessions/JwtProfile3";
import JwtProfile4 from "app/views/sessions/JwtProfile4";
import NotFound from "app/views/sessions/NotFound";
import ForgotPassword from "app/views/sessions/ForgotPassword";
import materialRoutes from "app/views/material-kit/MaterialRoutes";
import Privacy from "Documentation/Privacy";



const root = createRoot(document.getElementById("root"));

root.render(
  
  
  <SettingsProvider>
    <MatxTheme>
      <MatxSuspense>
        <CssBaseline/>
        <Router>
          <Routes element={<MatxLayout/>}> 
          
            <Route 
              path="/" 
              element={<JwtLogin/>}
            />
            <Route
              path="*"
              element={<NotFound/>}
            />
            <Route
              path="/signup"
              element={<JwtRegister/>}
            />
            <Route
              path="/register1"
              element={<JwtProfile1/>}
            />
            <Route
              path="/register2"
              element={<JwtProfile2/>}
            />
            <Route
              path="/register3"
              element={<JwtProfile3/>}
            />
            <Route
              path="/register4"
              element={<JwtProfile4/>}
            />
            <Route
              path="/forgot"
              element={<ForgotPassword/>}
            />
            <Route 
              path="/home" 
              element={<MatxLayout/>}
            />
            <Route
              element={materialRoutes}
            />
            <Route
              path="/analytics"
              element={<Analytics/>}
            />
            <Route
              path="/privacypolicy"
              element={<Privacy/>}
            />
            

              
            
          </Routes>
          
        </Router>

        
      </MatxSuspense>
    </MatxTheme>
  </SettingsProvider>
  
);

// for IE-11 support un-comment cssVars() and it's import in this file
// and in MatxTheme file

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
