import { Formik } from "formik";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Card, Checkbox, Grid, TextField, useTheme, Box, styled } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";

import {db} from "firebase-config";
import {where} from "firebase/compat/firestore"
import {auth} from "firebase-config"; 
import { Paragraph } from "app/components/Typography";


// STYLED COMPONENTS
const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center"
}));

const JustifyBox = styled(FlexBox)(() => ({
  justifyContent: "center"
}));

const ContentBox = styled(JustifyBox)(() => ({
  height: "100%",
  padding: "32px",
  background: "rgba(0, 0, 0, 0.01)"
}));

const JWTProfile2 = styled(JustifyBox)(() => ({
  background: "#1A2038",
  minHeight: "100vh !important",
  "& .card": {
    maxWidth: 800,
    minHeight: 400,
    margin: "1rem",
    display: "flex",
    borderRadius: 12,
    alignItems: "center"
  }
}));

// initial login credentials
const initialValues = {
  bio: "",
  businesslocation: "",
  businesssize: ""
};

//form field validation schema
const validationSchema = Yup.object().shape({
  bio: Yup.string()
    .required("Bio is required!"),
  businesslocation: Yup.string()
    .required("Business Location is required!"),
  businesssize: Yup.string()
    .required("Business Size is required!"),
});

export default function JwtProfile2() {
  const theme = useTheme();
  
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      await db.collection("Brands").doc(auth.currentUser.uid).update({
        Bio: values.bio,
        Location: values.businesslocation,
        Size: values.businesssize
      });
      navigate("/register3");
      setLoading(false);
    } catch (e) {
      alert(e.message);
      setLoading(false);
    }
  };

  return (
    <JWTProfile2>
      <Card className="card">
        <Grid container>
          <Grid item sm={6} xs={12}>
            <ContentBox>
              <img
                width="100%"
                alt="Register"
                src="/assets/images/illustrations/posting_photo.svg"
              />
            </ContentBox>
          </Grid>

          <Grid item sm={6} xs={12}>
            <Box p={4} height="100%">
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
                >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      name="bio"
                      label="Business Description"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.bio}
                      onChange={handleChange}
                      helperText={touched.bio && errors.bio}
                      error={Boolean(errors.bio && touched.bio)}
                      sx={{ mb: 3 }}
                    />

                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      name="businesslocation"
                      label="Business Location"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.businesslocation}
                      onChange={handleChange}
                      helperText={touched.businesslocation && errors.businesslocation}
                      error={Boolean(errors.businesslocation && touched.businesslocation)}
                      sx={{ mb: 3 }}
                    />
                    <TextField
                      fullWidth
                      size="small"
                      name="businesssize"
                      type="text"
                      label="Number of Employees"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.businesssize}
                      onChange={handleChange}
                      helperText={touched.businesssize && errors.businesssize}
                      error={Boolean(errors.businesssize && touched.businesssize)}
                      sx={{ mb: 2 }}
                    />

                    <LoadingButton
                      type="submit"
                      color="primary"
                      loading={loading}
                      variant="contained"
                      sx={{ mb: 2, mt: 3 }}>
                      Next
                    </LoadingButton>

                    <Paragraph>
                      Already have an account?
                      <NavLink
                        to="/session/signin"
                        style={{ color: theme.palette.primary.main, marginLeft: 5 }}>
                        Login
                      </NavLink>
                    </Paragraph>
                  </form>
                )}
              </Formik>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </JWTProfile2>
  );
}
