import useSettings from "app/hooks/useSettings";
import bazuka from "assets/bazuka.jpg";

export default function MatxLogo({ className }) {
  const { settings } = useSettings();
  const theme = settings.themes[settings.activeTheme];

  return (
    <div
      className={className}
      style={{
        display: 'inline-block',
        padding: '10px',
        //backgroundColor: theme.palette.primary.main
      }}
    >
      <img
        src={bazuka}
        alt="Bazuka"
        style={{
          width: '100%',
          height: 'auto',
          filter: theme.palette.primary.light
        }}
      />

    </div>
  );
}
