import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Grid, useTheme, Box, styled } from "@mui/material";
import { LoadingButton } from "@mui/lab";


import {store} from "firebase-config";
import {auth} from "firebase-config";



// STYLED COMPONENTS
const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center"
}));

const JustifyBox = styled(FlexBox)(() => ({
  justifyContent: "center"
}));

const ContentBox = styled(JustifyBox)(() => ({
  height: "100%",
  padding: "32px",
  background: "rgba(0, 0, 0, 0.01)"
}));

const JWTProfile4 = styled(JustifyBox)(() => ({
  background: "#1A2038",
  minHeight: "100vh !important",
  "& .card": {
    maxWidth: 800,
    minHeight: 400,
    margin: "1rem",
    display: "flex",
    borderRadius: 12,
    alignItems: "center"
  }
}));

// initial login credentials
const initialValues = {
  selectedLogo: null,
  selectedBanner: null
}; 

const state = {
    //Initialize no file is selected
    selectedLogo: null,
    selectedBanner:null
};





export default function JwtProfile4() {
  

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedBanner, setSelectedBanner] = useState(null);
  
  const handleFileUpload = (event) => {
    setSelectedFile(event.target.files[0]);      
  };
  const handleBannerUpload = (event) => {
    setSelectedBanner(event.target.files[0]);      
  };

  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      values.preventDefault()
      const logoData = new FormData();
      logoData.append('file', selectedFile);
      //logoData.append('fileName', selectedFile.name);

      values.preventDefault()
      const bannerData = new FormData();
      bannerData.append('file', selectedBanner);
      bannerData.append('fileName', selectedBanner.name);
      
        
      await store.ref(auth.currentUser.uid).put(selectedFile).on(
        "state_changed", alert("Success"), alert
      );
      await store.ref(auth.currentUser.uid).put(selectedBanner).on(
        "state_changed", alert("Success"), alert
      );
      
      navigate("/");
      setLoading(false);
    } catch (e) {
      alert(e.message);
      setLoading(false);
    }
  };

  return (
    <JWTProfile4>
      <Card className="card">
        <Grid container>
          <Grid item sm={6} xs={12}>
            <ContentBox>
              <img
                width="100%"
                alt="Register"
                src="/assets/images/illustrations/posting_photo.svg"
              />
            </ContentBox>
          </Grid>

          <Grid item sm={6} xs={12}>
            <Box p={5} height="100%">
              <div>
                <form onSubmit={handleFormSubmit}>
                    
                    <box
                    fullWidth
                    >
                        <h3 >Upload Logo</h3>
                        <input type="file" onChange={handleFileUpload}/>
                    </box>

                    <box
                    fullWidth
                    >
                        <h3 >Upload Banner</h3>
                        <input type="file" onChange={handleBannerUpload}/>
                    </box>
                    <box fullWidth height={10}/>
                    <box
                    fullWidth
                    height={10}
                    >
                    <LoadingButton
                      type="submit"
                      color="primary"
                      loading={loading}
                      variant="contained"
                      sx={{ my: 2 }}>
                      Upload 
                    </LoadingButton>
                    </box>
                </form>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </JWTProfile4>
  );
}
