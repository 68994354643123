import React from 'react';
import {Typography, Container} from '@mui/material';

const Privacy = () => {
    return(
        <div>
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    
                }}
            >
                
                <Typography variant="h3">
                    Privacy Policy
                </Typography>
                <Typography variant="body1">
                    The privacy policy for Bazuka, "We", "Us", or "Our", 
                    describe how and why we might access, collect, store, use, and/or share "process" your
                    personal information when you use our services.
                </Typography>
                <Typography variant="body2">
                    Questions or concerns? Reading this Privacy Policy will help you
                    understand your privacy rights and choices. We are responsible for making
                    decisions about how your personal information is processed. If you do not
                    agree with our policies and practices, please do not use our Services.
                </Typography>
                <Typography variant="h6">Summary of Key Points</Typography>
                <Typography variant="body3">
                    This summary provides key points from our Privacy Policy, but you
                    can find out more details about any of these topics below
                    to find the section you are looking for.
                </Typography>
                <Typography variant="body4">
                    What personal information do we process? When you visit, use, or
                    navigate our Services, we may process personal information depending on
                    how you interact with us and the Services, the choices you make, and the
                    products and features you use. Learn more about personal information you
                    disclose to us.
                </Typography>
                <Typography variant="body5">
                    Do we process any sensitive personal information? Some of the
                    information may be considered "special" or "sensitive" in certain
                    jurisdictions, for example your racial or ethnic origins, sexual orientation,
                    and religious beliefs. We do not process sensitive personal information.
                </Typography>
                <Typography variant="body6">
                    Do we collect any information from third parties? We may collect
                    information from public databases, marketing partners, social media
                    platforms, and other outside sources. Learn more about information
                    collected from other sources.
                </Typography>
                <Typography variant="body7">
                    How do we process your information? We process your information to
                    provide, improve, and administer our Services, communicate with you, for
                    security and fraud prevention, and to comply with law. We may also
                    process your information for other purposes with your consent. We process
                    your information only when we have a valid legal reason to do so. Learn
                    more about how we process your information.
                </Typography>
                <Typography variant="body8">
                    In what situations and with which parties do we share personal
                    information? We may share information in specific situations and with specific
                    third parties. Learn more about when and with whom we shareyour personal information.
                </Typography>
                <Typography variant="body9">
                    What are your rights?
                    Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about
                    your privacy rights.
                </Typography>
                <Typography variant="h5">
                    1. WHAT INFORMATION DO WE COLLECT?
                        Personal information you disclose to us
                </Typography>
                <Typography variant="body10">
                    In Short:
                    We collect personal information that you provide to us.
                    We collect personal information that you voluntarily provide to us when you
                    register on the Services,
                    express an interest in obtaining information aboutus or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
                </Typography>
                <Typography variant="body11">
                    Sensitive Information.
                    We do not process sensitive information.
                    All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
                </Typography>
                <Typography variant="h5">
                    Information automatically collected
                </Typography>
                <Typography variant="body12">
                    In Short:
                    Some information — such as your Internet Protocol (IP) addressand/or browser and device characteristics — is collected automaticallywhen you visit our Services.
                    We automatically collect certain information when you visit, use, ornavigate the Services. This information does not reveal your specificidentity (like your name or contact information) 
                    but may include device andusage information, such as your IP address, browser and devicecharacteristics, operating system, language preferences, referring URLs,device name, country, 
                    location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, 
                    and for ourinternal analytics and reporting purposes. Like many businesses, we also collect information through cookies and similar technologies.
                </Typography>
                <Typography variant="h5">
                    2. HOW DO WE PROCESS YOUR INFORMATION?
                </Typography>
                <Typography variant="body13">
                    In Short:
                    We process your information to provide, improve, and administerour Services, communicate with you, for security and fraud prevention, andto comply with law. 
                    We may also process your information for otherpurposes with your consent. We process your personal information for a variety of reasons,depending on how you interact with our Services, including:
                </Typography>
                <Typography variant="h5">
                    3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                </Typography>
                <Typography variant="body14">
                    In Short:
                    We may share information in specific situations described in thissection and/or with the following
                    third parties.
                    We may need to share your personal information in the following situations:
                </Typography>
                <Typography variant="body15">
                    Business Transfers. We may share or transfer your information inconnection with, or during negotiations of, any merger, sale ofcompany assets, financing, or 
                    acquisition of all or a portion of ourbusiness to another company.
                </Typography>
                <Typography variant="body16">
                    Affiliates.
                    We may share your information with our affiliates, inwhich case we will require those affiliates to honor this PrivacyNotice. 
                    Affiliates include our parent company and any subsidiaries,joint venture partners, or other companies that we control or that areunder common control with us.
                </Typography>
                <Typography variant="body17">
                    Business Partners.
                    We may share your information with ourbusiness partners to offer you certain products, services, orpromotions.
                </Typography>
                <Typography variant="h5">
                    4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                </Typography>
                <Typography variant="body18">
                    In Short:
                    We may use cookies and other tracking technologies to collectand store your information.
                    We may use cookies and similar tracking technologies (like web beaconsand pixels) to gather information when you interact with our Services.Some online tracking technologies 
                    help us maintain the security of our Services, prevent crashes, fix bugs, save your preferences, and assist with basic site functions.
                </Typography>
                <Typography variant="body19">
                    We also permit third parties and service providers to use online trackingtechnologies on our Services for analytics and advertising, including to helpmanage and display advertisements, 
                    to tailor advertisements to yourinterests, or to send abandoned shopping cart reminders (depending onyour communication preferences). 
                    The third parties and service providersuse their technology to provide advertising about products and servicestailored to your interests which may appear either on our Services or onother websites.
                    Specific information about how we use such technologies and how you canrefuse certain cookies is set out in our Cookie Notice.
                </Typography>
                <Typography variant="h5">
                    5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                </Typography>
                <Typography variant="body20">
                    In Short:
                    If you choose to register or log in to our Services using a socialmedia account, we may have access to certain information about you.
                    Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or X logins). 
                    Whereyou choose to do this, we will receive certain profile information 
                    about youfrom your social media provider. The profile information we receive mayvary depending on the social media provider concerned, but will ofteninclude your name, 
                    email address, friends list, and profile picture, as wellas other information you choose to make public on such a social mediaplatform.
                </Typography>
                <Typography variant="body21">
                    We will use the information we receive only for the purposes that aredescribed in this Privacy Notice or that are otherwise made clear to you onthe relevant Services. Please note that we do not control, 
                    and are notresponsible for, other uses of your personal information by your third-partysocial media provider. We recommend that you review their privacy noticeto understand how they collect, use, and share
                    your personal information,and how you can set your privacy preferences on their sites and apps.
                </Typography>
                <Typography variant="h5">
                    6. IS YOUR INFORMATION TRANSFERREDINTERNATIONALLY?
                </Typography>
                <Typography variant="body22">
                    In Short:
    	            We may transfer, store, and process your information in countriesother than your own.
                    Our servers are located in. If you are accessing our Services from outside, please be aware that your information may be transferred to, stored by, and
                    processed by us in our facilities and in the facilities of the third parties withwhom we may share your personal information (see" WHEN AND WITHWHOM DO WE SHARE YOUR PERSONAL INFORMATION?" above),
                    in and other countries.
                </Typography>
                <Typography variant="body23">
                    If you are a resident in the European Economic Area (EEA), UnitedKingdom (UK), or Switzerland, then these countries may not necessarilyhave data protection laws or other similar laws as comprehensive 
                    as thosein your country. However, we will take all necessary measures to protectyour personal information in accordance with this Privacy Notice andapplicable law.
                </Typography>
                <Typography variant="h5">
                    7. HOW LONG DO WE KEEP YOUR INFORMATION?
                </Typography>
                <Typography variant="body24">
                    In Short:
                    We keep your information for as long as necessary to fulfill the purposes outlined in this Privacy Notice unless otherwise required by law.
                    We will only keep your personal information for as long as it is necessaryfor the purposes set out in this Privacy Notice, unless a longer 
                    retentionperiod is required or permitted by law (such as tax, accounting, or otherlegal requirements).
                </Typography>
                <Typography variant="body25">
                    When we have no ongoing legitimate business need to process yourpersonal information, we will either delete or anonymize
                    such information,or, if this is not possible (for example, because your personal informationhas been stored in backup archives), then we will 
                    securely store yourpersonal information and isolate it from any further processing until deletionis possible.
                </Typography>
                <Typography variant="h5">
                    8. DO WE COLLECT INFORMATION FROMMINORS?
                </Typography>
                <Typography variant="body26">
                    In Short:
                    We do not knowingly collect data from or market to childrenunder 18 years of age. We do not knowingly collect, solicit data from, or market to children under18 years of age, 
                    nor do we knowingly sell such personal information. Byusing the Services, you represent that you are at least 18 or that you arethe parent or guardian of such a minor and consent 
                    to such minor dependents use of the Services. If we learn that personal information fromusers less than 18 years of age has been collected, we will deactivate theaccount and take 
                    reasonable measures to promptly delete such data fromour records. If you become aware of any data we may have collected fromchildren under age 18, please contact us at techstacksoftwares@gmail.com
                </Typography>
                <Typography variant="h5">
                    9. WHAT ARE YOUR PRIVACY RIGHTS?
                </Typography>
                <Typography variant="body27">
                    In Short:
                    You may review, change, or terminate your account at any time, depending on your country, province, or state of residence.
                    Withdrawing your consent: If we are relying on your consent to processyour personal information,
                    which may be express and/or implied consentdepending on the applicable law,
                    you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section
                    " HOW CAN YOU CONTACT US ABOUT THIS NOTICE? "below.
                </Typography>
                <Typography variant="body28">
                    However, please note that this will not affect the lawfulness of theprocessing before its withdrawal nor, when applicable law allows,
                    will itaffect the processing of your personal information conducted in reliance onlawful processing grounds other than consent.
                </Typography>
                <Typography variant="h5">
                    Account Information
                </Typography>
                <Typography variant="body29">
                    If you would at any time like to review or change the information in youraccount or terminate your account, you can:
                    Upon your request to terminate your account, we will deactivate or deleteyour account and information from our active databases. However, we mayretain some information 
                    in our files to prevent fraud, troubleshoot problems,assist with any investigations, enforce our legal terms and/or comply withapplicable legal requirements.
                </Typography>
                <Typography variant="h5">
                    10. CONTROLS FOR DO-NOT-TRACK FEATURES
                </Typography>
                <Typography variant="body30">
                    Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you canactivate to signal your privacy preference not 
                    to have data about youronline browsing activities monitored and collected. At this stage, no uniformtechnology standard for recognizing and implementing DNT signals hasbeen finalized. 
                    As such, we do not currently respond to DNT browsersignals or any other mechanism that automatically communicates yourchoice not to be tracked online. 
                    If a standard for online tracking is adoptedthat we must follow in the future, we will inform you about that practice in arevised version of this Privacy Notice.
                </Typography>
                <Typography variant="h5">
                    11. DO WE MAKE UPDATES TO THIS NOTICE?
                </Typography>
                <Typography variant="body31">
                    In Short:
                    Yes, we will update this notice as necessary to stay compliantwith relevant laws. We may update this Privacy Policy from time to time. The updated version will be indicated by an updated
                    "Revised" date at the top of this Privacy Policy. If we make material changes to this Privacy Notice, we may notify you either by prominently posting a notice of such changes or by directlysending 
                    you a notification. We encourage you to review this Privacy Notice frequently to be informed of how we are protecting your information.
                </Typography>
                <Typography variant="h5">
                    12. HOW CAN YOU CONTACT US ABOUT THISNOTICE?
                </Typography>
                <Typography variant="body32">
                    If you have questions or comments about this notice, you may
                    contact us by post at: techstacksoftwares@gmail.com
                </Typography>
                <Typography variant="h5">
                    13. HOW CAN YOU REVIEW, UPDATE, OR DELETETHE DATA WE COLLECT FROM YOU?
                </Typography>
                <Typography variant="body33">
                    Based on the applicable laws of your country, you may have the right torequest access to the personal information we collect from you, detailsabout how we have processed it, correct inaccuracies, 
                    or delete yourpersonal information. You may also have the right to withdraw your consentto our processing of your personal information. These rights may be limitedin some circumstances by applicable 
                    law. To request to review, update, ordelete your personal information, please fill out and submit a data subject access request.
                </Typography>
                
            </Container>
            <footer
                style = {{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '10vh',
                    backgroundColor: '#f0f0f0'
                }}
            >
                <Typography variant="body209">
                     © {new Date().getFullYear()} Bazuka
                </Typography>

            </footer>
        </div>
    );
}

export default Privacy;