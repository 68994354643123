import { Formik } from "formik";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Card, Grid, TextField, useTheme, Box, styled } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";

import {db} from "firebase-config";
import { Paragraph } from "app/components/Typography";
import { auth } from "firebase-config";


// STYLED COMPONENTS
const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center"
}));

const JustifyBox = styled(FlexBox)(() => ({
  justifyContent: "center"
}));

const ContentBox = styled(JustifyBox)(() => ({
  height: "100%",
  padding: "32px",
  background: "rgba(0, 0, 0, 0.01)"
}));

const JWTProfile1 = styled(JustifyBox)(() => ({
  background: "#1A2038",
  minHeight: "100vh !important",
  "& .card": {
    maxWidth: 800,
    minHeight: 400,
    margin: "1rem",
    display: "flex",
    borderRadius: 12,
    alignItems: "center"
  }
}));

// initial login credentials
const initialValues = {
  registration: "",
  tax: "",
  businessname: ""
};

// form field validation schema
const validationSchema = Yup.object().shape({
  businessname: Yup.string()
    .required("Business Name is required!"),
  tax: Yup.string()
    .required("Business Tax Code is required!"),
  registration: Yup.string()
    .required("Business Registration Number is required!")
});

export default function JwtProfile1() {
  const theme = useTheme();
  
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      await db.collection("Brands").doc(auth.currentUser.uid).set({
        BusinessName: values.businessname,
        BusinessRegistrationNumber: values.registration,
        BusinessTaxCode: values.tax
      });
      navigate("/register2");
      setLoading(false);
    } catch (e) {
      alert(e.message);
      setLoading(false);
    }
  };

  return (
    <JWTProfile1>
      <Card className="card">
        <Grid container>
          <Grid item sm={6} xs={12}>
            <ContentBox>
              <img
                width="100%"
                alt="Register"
                src="/assets/images/illustrations/posting_photo.svg"
              />
            </ContentBox>
          </Grid>

          <Grid item sm={6} xs={12}>
            <Box p={4} height="100%">
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
                >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      name="businessname"
                      label="Business Name"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.businessname}
                      onChange={handleChange}
                      helperText={touched.businessname && errors.businessname}
                      error={Boolean(errors.businessname && touched.businessname)}
                      sx={{ mb: 3 }}
                    />

                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      name="registration"
                      label="Business Registration Number"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.registration}
                      onChange={handleChange}
                      helperText={touched.registration && errors.registration}
                      error={Boolean(errors.registration && touched.registration)}
                      sx={{ mb: 3 }}
                    />
                    <TextField
                      fullWidth
                      size="small"
                      name="tax"
                      type="text"
                      label="Business Tax Code"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.tax}
                      onChange={handleChange}
                      helperText={touched.tax && errors.tax}
                      error={Boolean(errors.tax && touched.tax)}
                      sx={{ mb: 2 }}
                    />

                    <LoadingButton
                      type="submit"
                      color="primary"
                      loading={loading}
                      variant="contained"
                      sx={{ mb: 2, mt: 3 }}>
                      Next
                    </LoadingButton>

                    <Paragraph>
                      Already have an account?
                      <NavLink
                        to="/session/signin"
                        style={{ color: theme.palette.primary.main, marginLeft: 5 }}>
                        Login
                      </NavLink>
                    </Paragraph>
                  </form>
                )}
              </Formik>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </JWTProfile1>
  );
}
