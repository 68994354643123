import { Formik } from "formik";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Card, Divider, Grid, Checkbox, useTheme, Box, styled } from "@mui/material";
import { LoadingButton } from "@mui/lab";


import {db} from "firebase-config";
import {auth} from "firebase-config";
import { Paragraph } from "app/components/Typography";




// STYLED COMPONENTS
const FlexBox = styled(Box)(() => ({
    display: "flex"
  }));

const JustifyBox = styled(FlexBox)(() => ({
  justifyContent: "center"
}));

const ContentBox = styled(JustifyBox)(() => ({
  height: "100%",
  padding: "32px",
  background: "rgba(0, 0, 0, 0.01)"
}));

const JWTProfile3 = styled(JustifyBox)(() => ({
  background: "#1A2038",
  minHeight: "100vh !important",
  "& .card": {
    maxWidth: 800,
    minHeight: 400,
    margin: "1rem",
    display: "flex",
    borderRadius: 12,
    alignItems: "center"
  }
}));

// initial login credentials
const initialValues = {
  shoe: false,
  sock: false,
  hat:false,
  watch:false,
  short:false,
  shirt:false,
  suit:false,
  glass:false,
  jewelry:false,
  bag:false,
  tshirt:false,
  sweater:false,
  jacket:false,
  perfume:false,
  makeup:false,
  wig:false,
  cosmetic:false,
  tie:false,
  belt:false,
  bedsheet:false,
  bedcover:false,
  pillowcover:false,
  curtain:false,
  carpet:false,
  umbrella:false,
  babywear:false,
  glove:false,
  skirt:false,
  bra:false,
  trouser:false,
  blouse:false,
  underwear:false,
  vest:false,
  scarf:false,
  pajama:false,
  raincoat:false,
  sportwear:false,
  lingerie:false

};



export default function JwtProfile3() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      await db.collection("Brands").doc(auth.currentUser.uid).update({
        catalogue: {
          Shoe: values.shoe,
          Sock: values.sock,
          Hat: values.hat,
          Watch: values.watch,
          Short: values.short,
          Shirt: values.shirt,
          Suit: values.suit,
          Glass: values.glass,
          Jewelry: values.jewelry,
          Bag: values.bag,
          Tshirt: values.tshirt,
          Sweater: values.sweater,
          Jacket: values.jacket,
          Perfume: values.perfume,
          MakeUp: values.makeup,
          Wig: values.wig,
          Cosmetic: values.cosmetic,
          Tie: values.tie,
          Belt: values.belt,
          BedSheet: values.bedsheet,
          BedCover: values.bedcover,
          PillowCover: values.pillowcover,
          Curtain: values.curtain,
          Carpet: values.carpet,
          Umbrella: values.umbrella,
          BabyWear: values.babywear,
          Glove: values.glove,
          Skirt: values.skirt,
          Bra: values.bra,
          Trouser: values.trouser,
          Blouse: values.blouse,
          UnderWear: values.underwear,
          Vest: values.vest,
          Scarf: values.scarf,
          Pajama: values.pajama,
          RainCoat: values.raincoat,
          SportsWear: values.sportwear,
          Lingerie: values.lingerie
        }
      });
      navigate("/register4");
      setLoading(false);
    } catch (e) {
      alert(e.message);
      setLoading(false);
    }
  };

  return (
    <JWTProfile3>
      <Card className="card">
        <Grid container>
          <Grid item sm={6} xs={12}>
            <ContentBox>
              <img
                width="100%"
                alt="Register"
                src="/assets/images/illustrations/posting_photo.svg"
              />
            </ContentBox>
          </Grid>

          <Grid item sm={6} xs={12}>
            <Box p={4} height="100%">
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                //validationSchema={validationSchema}
                >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <Box 
                     fullWidth
                     height={20}
                     p={4} 
                     display="flex"
                     alignItems="center">
                        <h1 fontSize={15}>Identify Catalogue</h1>
                     </Box>
                     <Divider/>
                     <Box
                     style = {{
                        overflowY: "auto",
                        maxHeight: "130px",
                        display: "flex",
                        flexGrow: 1,
                        flexDirection: "Column"
                     }}>
                    
                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="shoe"
                        onChange={handleChange}
                        checked={values.shoe}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        Shoe
                      </Paragraph>
                    </FlexBox> 

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="sock"
                        onChange={handleChange}
                        checked={values.sock}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        Sock
                      </Paragraph>
                    </FlexBox> 

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="hat"
                        onChange={handleChange}
                        checked={values.hat}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        Hat
                      </Paragraph>
                    </FlexBox> 

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="watch"
                        onChange={handleChange}
                        checked={values.watch}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        Watch
                      </Paragraph>
                    </FlexBox> 

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="short"
                        onChange={handleChange}
                        checked={values.short}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        Short
                      </Paragraph>
                    </FlexBox>

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="shirt"
                        onChange={handleChange}
                        checked={values.shirt}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        Shirt
                      </Paragraph>
                    </FlexBox>  

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="suit"
                        onChange={handleChange}
                        checked={values.suit}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        Suit
                      </Paragraph>
                    </FlexBox> 

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="glass"
                        onChange={handleChange}
                        checked={values.glass}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        Glass
                      </Paragraph>
                    </FlexBox> 

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="jewelry"
                        onChange={handleChange}
                        checked={values.jewelry}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        Jewelry
                      </Paragraph>
                    </FlexBox> 

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="bag"
                        onChange={handleChange}
                        checked={values.bag}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        Bag
                      </Paragraph>
                    </FlexBox> 

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="tshirt"
                        onChange={handleChange}
                        checked={values.tshirt}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        T-Shirt
                      </Paragraph>
                    </FlexBox> 

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="sweater"
                        onChange={handleChange}
                        checked={values.sweater}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        Sweater
                      </Paragraph>
                    </FlexBox> 

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="jacket"
                        onChange={handleChange}
                        checked={values.jacket}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        Jacket
                      </Paragraph>
                    </FlexBox> 

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="perfume"
                        onChange={handleChange}
                        checked={values.perfume}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        Perfume
                      </Paragraph>
                    </FlexBox> 

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="makeup"
                        onChange={handleChange}
                        checked={values.makeup}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        Make Up
                      </Paragraph>
                    </FlexBox> 

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="wig"
                        onChange={handleChange}
                        checked={values.wig}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        Wig
                      </Paragraph>
                    </FlexBox> 

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="cosmetic"
                        onChange={handleChange}
                        checked={values.cosmetic}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        Cosmetic
                      </Paragraph>
                    </FlexBox>

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="tie"
                        onChange={handleChange}
                        checked={values.tie}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        Tie
                      </Paragraph>
                    </FlexBox> 

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="belt"
                        onChange={handleChange}
                        checked={values.belt}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        Belt
                      </Paragraph>
                    </FlexBox>

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="bedsheet"
                        onChange={handleChange}
                        checked={values.bedsheet}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        Bed Sheet
                      </Paragraph>
                    </FlexBox>

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="bedcover"
                        onChange={handleChange}
                        checked={values.bedcover}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        Bed Cover
                      </Paragraph>
                    </FlexBox>   

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="pillowcover"
                        onChange={handleChange}
                        checked={values.pillowcover}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        Pillow Cover
                      </Paragraph>
                    </FlexBox>  

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="curtain"
                        onChange={handleChange}
                        checked={values.curtain}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        Curtain
                      </Paragraph>
                    </FlexBox> 

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="carpet"
                        onChange={handleChange}
                        checked={values.carpet}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        Carpet
                      </Paragraph>
                    </FlexBox> 

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="umbrella"
                        onChange={handleChange}
                        checked={values.umbrella}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        Umbrella
                      </Paragraph>
                    </FlexBox> 

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="babywear"
                        onChange={handleChange}
                        checked={values.babywear}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        Baby Wear
                      </Paragraph>
                    </FlexBox> 

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="glove"
                        onChange={handleChange}
                        checked={values.glove}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        Glove
                      </Paragraph>
                    </FlexBox> 

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="skirt"
                        onChange={handleChange}
                        checked={values.skirt}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        Skirt
                      </Paragraph>
                    </FlexBox>

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="bra"
                        onChange={handleChange}
                        checked={values.bra}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        Bra
                      </Paragraph>
                    </FlexBox>

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="trouser"
                        onChange={handleChange}
                        checked={values.trouser}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        Trouser
                      </Paragraph>
                    </FlexBox>

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="blouse"
                        onChange={handleChange}
                        checked={values.blouse}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        Blouse
                      </Paragraph>
                    </FlexBox>  

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="underwear"
                        onChange={handleChange}
                        checked={values.underwear}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        Under Wear
                      </Paragraph>
                    </FlexBox>  

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="vest"
                        onChange={handleChange}
                        checked={values.vest}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        Vest
                      </Paragraph>
                    </FlexBox>

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="scarf"
                        onChange={handleChange}
                        checked={values.scarf}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        Scarf
                      </Paragraph>
                    </FlexBox>

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="pajama"
                        onChange={handleChange}
                        checked={values.pajama}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        Pajama
                      </Paragraph>
                    </FlexBox> 

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="raincoat"
                        onChange={handleChange}
                        checked={values.raincoat}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        Rain Coat
                      </Paragraph>
                    </FlexBox>   

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="sportwear"
                        onChange={handleChange}
                        checked={values.sportwear}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        Sports Wear
                      </Paragraph>
                    </FlexBox> 

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="lingerie"
                        onChange={handleChange}
                        checked={values.lingerie}
                        sx={{ padding: 0 }}
                      />
                      
                      <Paragraph fontSize={13}>
                        Lingerie
                      </Paragraph>
                    </FlexBox>  

                     </Box>
                     <Divider/>
                    <LoadingButton
                      type="submit"
                      color="primary"
                      loading={loading}
                      variant="contained"
                      sx={{ mb: 2, mt: 3 }}>
                      Next
                    </LoadingButton>

                    <Paragraph>
                      Already have an account?
                      <NavLink
                        to="/session/signin"
                        style={{ color: theme.palette.primary.main, marginLeft: 5 }}>
                        Login
                      </NavLink>
                    </Paragraph>
                  </form>
                )}
              </Formik>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </JWTProfile3>
  );
}
