import { MatxSuspense } from "app/components";
import useSettings from "app/hooks/useSettings";
import  {MatxLayouts}  from "./index";



const MatxLayout = () =>{
  const { settings } = useSettings();
  const Layout = MatxLayouts[settings.activeLayout];

  return <MatxSuspense> <Layout/> </MatxSuspense> 
}
export default MatxLayout;
