import firebase from 'firebase/compat/app';                                                                
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';


const firebaseConfig = {
  apiKey: "AIzaSyC6LU-39jkhRNvdeS880M-Gbp3hCghzK3s",
  authDomain: "bazuka254-001.firebaseapp.com",
  databaseURL: "https://bazuka254-001-default-rtdb.firebaseio.com",
  projectId: "bazuka254-001",
  storageBucket: "bazuka254-001.appspot.com",
  messagingSenderId: "564124803593",
  appId: "1:564124803593:web:f78b8c2a7075250e348ed8",
  measurementId: "G-W1HXBLD77W"
};

//Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
const auth = firebaseApp.auth();
const store = firebaseApp.storage();

export {auth, db, store};

